import { Injectable } from '@angular/core';

/**
 * Variables used
 */

export enum GAPageType {
  MainPage = 'MainPage',
  TermsOfService = 'Terms Of Service',
  PrivacyPolicy = 'Privacy Policy',
  Footer = 'Footer',
  Header = 'Header',
  ContactUs = 'Contact Us Form',
  PersonalInfo = 'Personal Info Form',
  AdditionalInfo = 'Additional Info Form',
  BusinessInfo = 'Business Info Form',
  BankVerification = 'Bank Verification Form',
  OfferPage = 'Offer Page',
  ManualReviewPage = 'Manual Review Page',
  DeniedPage = 'Denied Page',
  OnePageApplication = 'One page Non-organic application',
}

export enum GAEventCategory {
  SendMessage = 'Send Message',
  Next = 'Next',
  Previous = 'Previous',
  ConnectAccount = 'Connect Account',
  ViewAgreement = 'View The Agreement',
  SocialMedia = 'Social Media',
  ApplyNow = 'Apply Now',
  MenuItem = 'Menu Item',
  GetStarted = 'Get Started',
  PagesItem = 'Page',
  TrackTime = 'Track time',
  StepperSwitch = 'Stepper Switch',
  TermsOfService = 'Terms Of Service',
  PrivacyPolicy = 'Privacy Policy',
  W2Button = 'W2',
  Both = 'Both',
  IndependentContractor = '1099',
  Neither = 'Neither',
}

export enum GAEventAction {
  Click = 'Button click',
  Time = 'Time',
  OpenPage = 'Open page',
}

export interface GAEvent {
  eventCategory: GAEventCategory;
  eventLabel?: string;
  eventAction: GAEventAction;
  eventValue?: number;
}

/**
 * Google analytics function
 */
declare let ga: Function;

@Injectable()
export class GoogleAnalyticsService {
  private isEnabled: boolean = false;

  constructor() {}

  public logClick(
    eventCategory: GAEventCategory,
    pageType: GAPageType,
    payload: string = '',
    eventLabel?: string,
  ): void {
    ga('send', 'event', {
      eventAction: GAEventAction.Click,
      eventCategory:
        eventLabel === 'stepper'
          ? `${eventCategory} from ${pageType} to ${payload}`
          : payload.length
          ? ` ${eventCategory}: ${payload} was clicked on ${pageType}`
          : `${eventCategory} was clicked on ${pageType} ${payload}`,
    });
  }

  public logSpentTime(
    pageType: GAPageType,
    startTime: number,
    payload: string = '',
  ): void {
    const endTime: number = this.getTimestamp();
    const spentTime: number = Math.round((endTime - startTime) / 1000);
    ga('send', 'event', {
      eventAction: GAEventAction.Time,
      eventCategory: `${GAEventCategory.TrackTime}:
       ${spentTime} sec spent on ${pageType} ${payload}`,
    });
  }

  public sendPageView(page: string): void {
    ga('set', 'page', page);
    ga('send', 'pageview');
  }

  public logPageOpen(pageType: GAPageType): void {
    ga('send', 'event', {
      eventAction: GAEventAction.OpenPage,
      eventCategory: pageType,
    });
  }

  public getTimestamp(): number {
    return Date.now();
  }
}
